import React, { useState } from "react";
import {
  Authenticator,
  SignIn,
  SignUp,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
} from "aws-amplify-react";
import UserPool from "./UserPool";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

interface InputComponentState {
  email: string;
  password: string;
  messageVisible: boolean;
  message: string | null;
}

class LogIn extends React.Component<{}, InputComponentState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      email: "",
      password: "",
      messageVisible: false,
      message: null,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ password: event.target.value });
  }

  signIn = async () => {
    if (!this.state.email.trim() || !this.state.password.trim()) {
      this.setState({ message: "Email und Passwort angeben." });
      return;
    }

    this.setState({ message: null });

    const authDetails = new AuthenticationDetails({
      Username: this.state.email,
      Password: this.state.password,
    });

    const user = new CognitoUser({
      Username: this.state.email,
      Pool: UserPool,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        window.location.href = `https://app.signs.school?email=${this.state.email}`;
        console.log(`authenticated`);
      },
      onFailure: (error) => {
        this.setState({
          message: "Email nicht gefunden oder Passwort falsch.",
        });
      },
    });
  };

  render() {
    return (
      <body className="static-black-background">
        <div className="grid">
          <p className="white medium-large left-margin text-margin-top">
            Signs School
          </p>
          <div className="two centric some-top-margin max-width inline-block">
            <div className="grid half-width even-more-left-margin">
              <p className="white medium-large left-align">
                Mail und Passwort eingeben
              </p>
              <input
                className="signin-input half-width top-margin"
                placeholder="Email"
                value={this.state.email}
                onChange={(e) => this.handleEmailChange(e)}
                type="email"
                id="email"
              />
              <input
                className="signin-input half-width semi-top-margin"
                placeholder="Passwort"
                type="password"
                value={this.state.password}
                onChange={(e) => this.handlePasswordChange(e)}
                id="password"
              />
              <button
                className="loginButton half-width top-margin dark-blue-background white"
                id="loginButton"
                onClick={this.signIn}
              >
                Weiter
              </button>

              <a
                href="https://app.signs.school/lib/account/html/changePassword"
                className="dark-blue-color more-top-margin left half-width"
              >
                Passwort vergessen
              </a>
              <img
                src={require("./assets/lock-dynamic-clay.png")}
                className="fifth-width absolute half-left-margin"
              />
            </div>
          </div>
        </div>
        <p className="center top-margin white" id="message">
          {this.state.message}
        </p>
      </body>
    );
  }
}

export default LogIn;
